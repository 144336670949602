@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;700&display=swap");

@import "~ngx-toastr/toastr";

html,
body,
.main {
  height: 100%;
  margin: 0;
  font-family: "Quicksand", sans-serif;
}
body {
  background: #f5f5f5;
}
p,
td,
a,
button,
select,
label,
input,
li,
span {
  font-size: 13px !important;
}
p,
li {
  line-height: 24px !important;
}
a {
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}

p {
  margin-bottom: 0px !important;
}
.btn:focus {
  box-shadow: none;
}
tr td {
  vertical-align: middle !important;
}
table tr th {
  font-size: 14px;
}
.text_color {
  color: #001a56;
}
.full-height {
  height: 100%;
}
.text-softblue {
  color: #0572ae !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.cust_shadow {
  box-shadow: 0 0 10px #ddd;
}

.bg-softblue {
  background: #001a56;
}
.doctors_slide_panel {
  position: absolute;
  top: 20%;
  width: 100%;
  display: block;
  text-align: center;
}
.carousel-inner .carousel-item img {
  width: 50%;
  margin: 0 auto;
  display: table;
  padding: 2% 0;
}
.doctor-info {
  min-height: 90px !important;
}
.doctor-info p {
  color: #fff;
  font-style: italic;
  font-size: 13px;
  width: 80%;
  margin: 0 auto;
  line-height: 22px;
}
.slider-indicators {
  bottom: -10%;
}
.slider-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.btn-section {
  width: 100%;
  position: absolute;
  bottom: 8%;
}
.btn-section p {
  font-weight: 100;
  font-size: 11px;
  color: #f9f9f9;
}
.btn-section .sale-btn {
  width: 35%;
  margin: 0 auto;
}
#Contactsales .modal-dialog .modal-body p {
  font-weight: 500;
  color: #555 !important;
}
.sale-btn {
  color: #fff;
  padding: 10px 0px;
  border: 1px solid #f9f9f9;
  background: transparent;
}
.sale-btn:hover {
  color: #fff;
  background: #116ea1;
  transition: 0.3s all;
}
.logo {
  width: 35%;
  margin-left: auto;
}

.login-section {
  width: 100%;
  position: relative;
  top: 18%;
}
.login-page input,
.login-page button {
  height: 50px;
}
.login-page button {
  font-size: 20px;
}
.login_btn:hover {
  background: #1b3776;
}
.company-info {
  position: absolute;
  bottom: 5%;
  right: 12%;
  color: #555;
}
.cust-drop {
  width: 250px;
  transform: translate3d(459px, 42px, 0px);
}

.cust-drop li a {
  padding: 5px 10px;
  text-decoration: none;
  color: #001a56 !important;
  font-weight: bold;
  display: block;
  transition: 0.3s all ease;
}
.cust-drop li:hover a {
  background: #001a56;
  color: #fff !important;
  border-radius: 5px;
}
.dropdown button i {
  font-size: 24px;
  position: relative;
  top: 5px;
}
//
ul.segmented-control li {
  // border: 1px solid #ddd;
  width: 25%;
}
ul.segmented-control li:hover a {
  text-decoration: none;
}
.segmented-control {
  border-radius: 8px;
  overflow: hidden;
}
.segmented-control__item {
  list-style-type: none;
}
.segmented-control__input {
  position: absolute;
  visibility: hidden;
}
.segmented-control__label {
  display: block;
  padding: 10px;
  margin: -1px 0;
  margin-left: 20px;
  background: #c8d3d9;
  padding-left: 20px;
  cursor: pointer;
  border-radius: 5px;
}
.segmented-control__item:first-child .segmented-control__label {
  border-left-width: 0;
}
.segmented-control__label:hover {
  background: #001a56;
  color: #fff;
}
.segmented-control__input:checked + .segmented-control__label {
  background: #001a56;
  color: #fff;
  // border-radius: 20px;
}
.notification {
  position: absolute;
  right: 12px;
  width: 49px;
  background: #fff;
  height: 30px;
  line-height: 30px;
  border: 1px solid #ddd;
  text-align: center;
  color: #555;
  top: 8px;
  border-radius: 2px;
}

.navigation-sidebar ul li a {
  color: #fff;
  padding: 10px !important;
  display: block;
}

.navigation-sidebar {
  height: 100vh;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.text-size {
  font-size: 20px !important;
  font-weight: bold;
}
table th {
  color: #001a56 !important;
}
table thead th {
  border-bottom: 1px solid #fff !important;
}
table tr th,
table tr td {
  border-top: 1px solid transparent !important;
}
table tr th:first-child,
table tr td:first-child {
  padding-left: 30px !important;
  border-radius: 5px 0 0 5px;
}
tr th:last-child {
  border-radius: 0 5px 5px 0;
}
table.clinical_trail tr th:last-child {
  width: 15%;
}

table tr th {
  border-bottom: 1px solid transparent !important;
}
table tr td {
  padding: 15px !important;
}
.actions li a {
  color: #001a56 !important;
  font-weight: bold;
}
.actions li a:hover {
  color: #0572ae !important;
  text-decoration: none;
}
.variant_title .back_icon {
  width: 30px;
  height: 30px;
  top: -2px;
  text-align: center;
  line-height: 34px;
  background-color: #b2ebf2;
  color: #555;
  border-radius: 20px;
  display: block;
  position: relative;
  left: 0;
  z-index: 9;
}
.variant_title .back_icon a i {
  font-size: 20px;
}
.variant_title ul {
  position: relative;
  left: 3%;
  background-color: rgb(131, 128, 128);
  color: #fff;
  padding: 5px 20px;
  border-radius: 5px;
}
.variant_title ul li {
  padding: 0 10px;
  position: relative;
  font-size: 12px;
  text-transform: uppercase;
}

.variant_title ul li:after {
  position: absolute;
  content: "";
  right: 0;
  width: 2px;
  top: 1px;
  height: 13px;
  background: #fff;
}
.variant_title ul li:nth-child(3)::after {
  background: transparent;
}
.right_section {
  position: absolute;
  top: 0;
  right: 0;
}
.add_post {
  width: 200px;
  background: #001a56;
  color: #fff;
  display: block;
  text-align: center !important;
  padding: 8px;
  transition: all 0.3s;
  border-radius: 5px;
}
.add_post:hover {
  background: #0056b3;
  color: #fff;
}
.modal-header h5 {
  color: #001a56 !important;
  font-size: 1.25rem;
}
.modal-header a i {
  color: #001a56;
}
.modal-body button,
.save_btn {
  width: 150px;
  color: #fff;
  background: #001a56;
}
.modal-body label,
form label {
  text-align: right;
}
.reports_tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff !important;
  background-color: #001a56 !important;
  border-color: transparent;
}
.reports_tabs ul {
  border-bottom: transparent;
}
.reports_tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: 5px;
  margin-right: 8px;
  background: #e9e9e9;
  color: #555;
}
.reports_tabs .nav-tabs .nav-item:hover .nav-link {
  background-color: #001a56;
  color: #fff;
  transition: 0.3s all ease;
}
.save_btn:hover {
  background: #1b3776;
  color: #fff;
}
.cust_form-ckeck {
  top: -10px;
}
//
.switch_panel .switch_on {
  right: -22px;
  font-size: 13px;
}
.switch_panel .slider:before {
  height: 10px !important;
  width: 10px !important;
}
.switch_panel .slider {
  bottom: 10px !important;
}

.switch_panel .switch_off {
  left: 6% !important;
  font-size: 13px;
}
.switch_panel .switch {
  top: 5px !important;
}
.switch_panel .switch_on {
  right: -13% !important;
}
.filter-csv_table table tr td {
  padding: 15px !important;
}

.clinicalTrail-model {
  max-width: 1000px !important;
}
.multiselect-dropdown {
  font-size: 14px !important;
}
.dropdown-multiselect__caret::before {
  border-width: 6px 6px 0 !important;
}
.top_bar {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 9;
}

.tableFixHead {
  overflow-y: auto;
  height: calc(100vh - 150px);
}
.tableFixHead thead th {
  position: sticky;
  top: 0;
}

.sidenav_fixed {
  position: fixed;
  height: 100vh;
  top: 10%;
  width: 240px;
}
// .content_wrapper{
//   position: relative;
//   top: 15%;s
//   padding-top: 5rem;
// }
.download_btn {
  position: fixed;
  right: 0;
  z-index: 999;
}

.interpretationtab table th:first-child {
  border: 1px solid !important;
  border-collapse: separate !important;
  padding-left: 30px !important;
  border-radius: 5px 0 0 5px;
}
.interpretationtab table tr td {
  border: 1px solid !important;
  border-collapse: separate !important;
  padding-left: 30px !important;
  border-radius: 5px 0 0 5px;
}

.data-spinner-parent {
  height: calc(100vh - 200px);
}
.data-spinner-child {
  margin: auto;
}
.clinical_trail-title a {
  font-size: 15px !important;
  font-weight: bold;
}
.variant_title h5 span {
  font-size: 18px !important;
}
.cust-checkbox td .form-check-input {
  top: -11px;
}
.remove_space table tbody tr td {
  // white-space: nowrap !important;
  letter-spacing: -1px;
}
@media print {
  .remove_space .table tbody tr td {
    letter-spacing: -1px !important;
    white-space: nowrap !important;
    // background-color: red !important;
  }
}
