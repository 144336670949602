/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.variant_title{
  position: sticky;
    padding: 10px 20px;
    background: #fff;
    color: #218dca;
    z-index: 1;
    top: 9.6%;
    border-bottom: 1px solid #ddd;
    transition: 0.3s all ease-in;
}
.zmdi.zmdi-close {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #a09a9a;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  transition: 0.5s all ease;
}
.zmdi.zmdi-close:hover{background: #6c6e71; color: #fff;}
.download-btn span{font-size: 13px; font-weight: bold;}
.download-btn i{font-size: 18px;
  position: relative;
  top: 3px;
  left: -4px;
  font-weight: 500;}
.download-btn{height: 40px; width: 180px; position: fixed; right:0}
a.back_btn .zmdi.zmdi-long-arrow-left, .zmdi.zmdi-plus{
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: #001a56;
  color: #fff;
  position: relative;
  top: 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  margin-right: 5px;
  border: none;
  transition: 0.5s all ease;
}
a.back_btn:hover .zmdi.zmdi-long-arrow-left{background-color: #0056b3; }
/* .zmdi.zmdi-plus{background: transparent!important;} */
.variant_view .row .col-3 .col-6{word-break: break-all!important;}
.break-word{word-wrap: break-word;}
.input_check{position: relative; top: 3px;}
.header{display: flex; justify-content: space-between; align-items: center;}
/* .ngx-pagination .current{margin: 0 2px; background-color: #001a56!important; border-radius: 5px;}
.ngx-pagination li {background: #e9ecef; color: #000;border-radius: 5px!important; transition: all 0.3s ease}
.ngx-pagination a:hover{background: #001a56!important; border-radius: 5px!important; color: #fff!important;} */
.row.header .logo img, .onco_logo{
  width: 140px!important;
}
